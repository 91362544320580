<template>
  <div>
    <iframe
      id="liveDealerIframe"
      :style="
        `width: 100%;
        height: 100%;
        position: absolute;`
      "
      :src="homeURL"
      frameborder="0"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    url: { type: String, required: true }
  },
  computed: {
    ...mapGetters("marketingPanels", ["panels"]),
    homeURL() {
      return this.url;
    },
    bottomNavMenuHeight() {
      return (
        `${
          document.getElementById("bottom-nav-menu")?.getBoundingClientRect()
            .height
        }px` || "0px"
      );
    },
    hasMarketingData() {
      return this.panels?.MarketingPanels?.length > 0 || false;
    },
    marketingPanelHeight() {
      return this.hasMarketingData
        ? `${
            document
              .getElementById("MarketingPanelCarousel")
              ?.getBoundingClientRect().height
          }px`
        : "0px";
    },
    heightParamInURL() {
      const height = new URL(this.url).searchParams.get("height");
      return height ? `${height}px` : null;
    },
    iframeHeight() {
      return this.smallLayout
        ? `calc(100% + ${this.bottomNavMenuHeight} - ${this.marketingPanelHeight})`
        : `${this.heightParamInURL || "100%"}`;
    }
  }
};
</script>
